<template>
  <div class="px-4 pb-6">
    <v-icon class="mx-auto d-block mb-3" color="warning" size="54"
      >$warning</v-icon
    >

    <div class="font-medium-16 gray13--text text-center mb-3">
      {{ $t("panel.transactionsPage.depositTransactions.acceptModal.title") }}
    </div>

    <div class="gray9--text text-center mb-2">
      {{ $t("panel.transactionsPage.depositTransactions.acceptModal.desc") }}
    </div>

    <div
      class="d-sm-flex align-center justify-sm-space-between text-center my-4"
    >
      <div class="font-regular-12 gray9--text">
        {{
          $t("panel.transactionsPage.depositTransactions.acceptModal.amount")
        }}
      </div>

      <span class="gray12--text">{{ data.amountString }} {{ data.iso }}</span>
    </div>
    <v-divider></v-divider>

    <div
      class="d-sm-flex align-center justify-sm-space-between text-center my-4"
    >
      <div class="font-regular-12 gray9--text">
        {{ $t("panel.transactionsPage.depositTransactions.acceptModal.fee") }}
      </div>

      <span class="gray12--text">{{ data.feeString }} {{ data.iso }}</span>
    </div>
    <v-divider></v-divider>

    <div
      class="d-sm-flex align-center justify-sm-space-between text-center my-4"
    >
      <div class="font-regular-12 gray9--text">
        {{
          $t("panel.transactionsPage.depositTransactions.acceptModal.crypto")
        }}
      </div>

      <span class="gray12--text">{{ data.cryptoName }}</span>
    </div>
    <v-divider></v-divider>

    <div
      class="d-sm-flex align-center justify-sm-space-between text-center my-4"
    >
      <div class="font-regular-12 gray9--text">
        {{
          $t("panel.transactionsPage.depositTransactions.acceptModal.network")
        }}
      </div>

      <span class="gray12--text">{{ data.network }}</span>
    </div>

    <div class="d-flex align-sm-center justify-space-between">
      <v-btn width="210" height="43" depressed color="gray2" @click="close">
        <span class="font-semiBold-14">
          {{
            $t("panel.transactionsPage.depositTransactions.acceptModal.cancel")
          }}
        </span>
      </v-btn>

      <v-btn
        width="210"
        height="43"
        depressed
        color="primary"
        :loading="loading"
        @click="accept"
      >
        <span class="font-semiBold-14">
          {{
            $t("panel.transactionsPage.depositTransactions.acceptModal.accept")
          }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { AccountService } from "@/services";

export default {
  name: "index",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    accept() {
      this.loading = true;
      AccountService.acceptTransaction({
        transactionId: this.data.trxId,
      })
        .then((res) => {
          this.$toast.success(res.data.message, {
            icon: this.$vuetify.icons.values.checkMarkDone,
          });
          this.$emit("updateRecord", this.data.trxId);
          this.$emit("close");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
